import APIController from '../core-api-controller';

//Listing API's

export const fetchPatientsApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/patients${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

//Lead Detail Page API's

export const fetchPatientDetail = (patientId = '') => {
  const path = `${APIController.endpointApi}/patients/${patientId}`;
  return APIController.getApiCallback(path);
};

export const updatePatientDetail = (data, patientId = '') => {
  const path = `${APIController.endpointApi}/patients/${patientId}`;
  return APIController.putCallback(path, data);
};

export const createPatient = (data = {}) => {
  const path = `${APIController.endpointApi}/patients`;
  return APIController.postCallback(path, data);
};

export const createPatientContactApi = (data = {}) => {
  const path = `${APIController.endpointApi}/contacts`;
  return APIController.postCallback(path, data);
};

export const editPatientContactApi = (contactId = '', data = {}) => {
  const path = `${APIController.endpointApi}/contacts/${contactId}`;
  return APIController.putCallback(path, data);
};

export const deletePatientContactApi = (contactId = '') => {
  const path = `${APIController.endpointApi}/contacts/${contactId}`;
  return APIController.deleteCallback(path);
};

export const markFixedPatientContactApi = (contactId = '') => {
  const path = `${APIController.endpointApi}/contacts/${contactId}/mark_as_fixed`;
  return APIController.putCallback(path)
}

export const markUnFixedPatientContactApi = (contactId = '') => {
  const path = `${APIController.endpointApi}/contacts/${contactId}/mark_as_unfixed`;
  return APIController.putCallback(path)
}