export const FILTER_TYPES = {
  DROP_DOWN: 'dropdown',
  BOOLEAN: 'boolean',
  DATE: 'date',
  ADVANCE_INPUT: 'advance_input',
};

export const FILTER_KEY_TYPES_SLUGS = {
  EQUAL: '_eq',
  NOT_EQUAL: '_not_eq',
  CONTAINS: '_cont',
  NULL: '_null',
};

export const FILTER_KEY_TYPES_SLUGS_ARR = [
  FILTER_KEY_TYPES_SLUGS.EQUAL,
  FILTER_KEY_TYPES_SLUGS.NOT_EQUAL,
  FILTER_KEY_TYPES_SLUGS.CONTAINS,
  FILTER_KEY_TYPES_SLUGS.NULL,
];

export const FILTER_TYPES_OPTIONS = [
  {
    key: 1,
    label: 'Is Equals',
    value: FILTER_KEY_TYPES_SLUGS.EQUAL,
  },
  {
    key: 2,
    label: 'Is Contains',
    value: FILTER_KEY_TYPES_SLUGS.CONTAINS,
  },
  {
    key: 3,
    label: 'Not Equals',
    value: FILTER_KEY_TYPES_SLUGS.NOT_EQUAL,
  },
  {
    key: 4,
    label: 'Is Present',
    value: FILTER_KEY_TYPES_SLUGS.NULL,
    defaultValue: 'true',
  },
  {
    key: 5,
    label: 'Is Not Present',
    value: FILTER_KEY_TYPES_SLUGS.NULL,
    defaultValue: 'false',
  },
];

// _eq i.e exactly equal
// _not_eq i.e is not equal
// _cont i.e contains
// _null = false i.e is present
// _null = true i.e not present
