import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchTicketsApi } from '../../api/tickets-api';

const initialState = {
  tickets: [],
  statuses: [],
  types: [],
  activeColumns: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchTicketsListing = createAsyncThunk('tickets/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      ...updatedParamsObject,
    };
    const res = await fetchTicketsApi('', paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const TicketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTicketsLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setTicketsActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTicketsListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchTicketsListing.fulfilled, (state, action) => {
        if (state.loading) {
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.tickets = action?.payload?.data || [];
        state.statuses = action?.payload.statuses || [];
        state.types = action?.payload.types || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchTicketsListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setTicketsLoading, setTicketsActiveColumn } = TicketsSlice.actions;
export default TicketsSlice.reducer;
