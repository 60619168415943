import React, { Fragment, useState } from 'react';
import { Button, Icon, Modal } from '../../../common';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { deletePatientContactApi } from '../../../api/patients-api';

const DeletePatientContactModal = ({ contactId = '', onSuccessDelete = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onConfirmDelete = async () => {
    if (!contactId) return toast.error('Can not delete contact, Corrupt contact data!');
    try {
      setLoading(true);

      const res = await deletePatientContactApi(contactId);
      if (res && res?.status) {
        res?.data?.contacts && onSuccessDelete(res?.data?.contacts);
        toast.success('Contact Deleted Successfully.');
        setModalOpen(false);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button icon={'IoTrashBinOutline'} size={'small'} onClick={() => setModalOpen(true)} />

      <Modal
        title={'Delete Contact'}
        open={modalOpen}
        ModalBody={<div className="subtitle-2">{'Are you sure you want to delete this contact!'}</div>}
        handleCancel={() => setModalOpen(false)}
        handleOk={onConfirmDelete}
        okButtonProps={{ loading: loading, icon: <Icon icon={'IoTrashBinOutline'} size={'0.8em'} /> }}
        okText="Delete"
        width={580}
        showActions={true}
        destroyOnClose={true}
      />
    </Fragment>
  );
};

export default DeletePatientContactModal;
