import axios from 'axios';
import { REQUEST_TYPES } from '../../constants/generic-constants';
import { getCookie, removeAppCookies } from '../../utils/cookies-util';
const TIMEOUT = 90 * 1000;

export default class NetworkManager {
  static getHeaders() {
    return {
      authorization: `${getCookie('access-token')}`,
    };
  }
  static performRequest({ url, data, type, signal, params }) {
    let config = {
      method: type,
      url: url,
      data: data,
      params: params,
      headers: NetworkManager.getHeaders(),
      ...(signal ? { signal: signal } : {}),
    };
    return new Promise((resolve, reject) => {
      axios(config, {
        timeout: TIMEOUT,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log('Error:- ', error);
          if (error.response?.status === 403) {
            const status = error.response?.status;
            reject({ status, error });
          } else if ((error.response?.status === 401 && type === REQUEST_TYPES.GET) || type === REQUEST_TYPES.PATCH) {
            removeAppCookies();
            window.location.reload();
            reject(error);
            return;
          } else if (error?.response?.status === 502) {
            reject({ message: error?.response?.statusText ?? 'Bad Gateway' });
          } else {
            reject(error);
          }
        });
    });
  }
}
