import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Skeleton } from '../../../common';
import styles from './QuickFilters.module.scss';
import { BASE_PATH } from '../../../constants/admin-paths';
import { findQuickLink } from '../../../utils/filters-util';
import AddQuickFilter from '../add-quick-filter/AddQuickFilter';
import { selectQuickFilters } from '../../../reducers/selectors';
import { getQuickLinks } from '../../../reducers/quick-links-slice';
import ManageQuickFilters from '../manage-quick-filter/ManageQuickFilters';

const QuickFilters = ({ sourceClass = '', resetFilters = () => {} }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedChip, setSelectedChip] = useState(null);
  const { data: quickLinksData, loading } = useSelector(selectQuickFilters);

  useEffect(() => {
    !!sourceClass && dispatch(getQuickLinks(sourceClass));
  }, []);

  useEffect(() => {
    setSelectedChip(findQuickLink({ location: location, quickLinks: quickLinksData }));
  }, [location, quickLinksData]);

  const onClickQuickLink = (quickLink) => {
    if (selectedChip?.id === quickLink?.id) {
      resetFilters();
      return;
    }
    const link = quickLink.body.split('?');
    const path = link[0];
    const search = `?${link[1]}`;
    navigate({
      pathname: path,
      search: search,
    });
  };

  if (loading)
    return (
      <Card className={styles.quickLinksCard}>
        <Flex gap={12}>
          {[0, 1, 2].map((index) => (
            <Skeleton key={index} borderRadius={20} width={100} height={30} />
          ))}
        </Flex>
      </Card>
    );

  return (
    <Card className={styles.quickLinksCard}>
      <Flex justify="space-between">
        <Flex gap={12} className="flexGrow" wrap>
          {quickLinksData?.length > 0 &&
            quickLinksData?.map((quickLink) => (
              <div key={quickLink.id} className={cx({ [styles.selectedBtn]: selectedChip?.id === quickLink?.id })}>
                <Button size="medium" shape="round" onClick={() => onClickQuickLink(quickLink)}>
                  {quickLink?.name}
                </Button>
              </div>
            ))}
          <AddQuickFilter quickLinks={quickLinksData} sourceClass={sourceClass} />
        </Flex>
        <ManageQuickFilters quickLinks={quickLinksData} sourceClass={sourceClass} />
      </Flex>
    </Card>
  );
};

export default QuickFilters;
