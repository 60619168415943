import APIController from '../core-api-controller';

export const fetchStatusDetail = (statusId = '') => {
  const path = `${APIController.endpointApi}/statuses/${statusId}`;
  return APIController.getApiCallback(path);
};

export const updateStatusDetail = (data, statusId = '') => {
  const path = `${APIController.endpointApi}/statuses/${statusId}`;
  return APIController.putCallback(path, data);
};

export const fetchStatusesApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/statuses${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};
