import React from 'react';
import CopyToClipboard from '../copy-to-clipboard/CopyToClipboard';
import { Flex } from 'antd';

const TextWithCopy = ({
  value = '',
  containerClassName = '',
  textClassName = '',
  textStyle = {},
  gap = 5,
  copyBtnSize = 'small',
  valueToCopy = '',
}) => {
  const copyValue = valueToCopy ? valueToCopy : value;
  return (
    <Flex className={containerClassName} gap={gap}>
      <div className={textClassName} style={textStyle}>
        {value ?? '-'}
      </div>
      {copyValue && <CopyToClipboard btnSize={copyBtnSize} value={copyValue} />}
    </Flex>
  );
};

export default TextWithCopy;
