export const BASE_PATH = '/admin';

export const UP_PATH = '/up';

export const SIGNIN_PATH = BASE_PATH + '/signin';

export const LEADS_PATH = BASE_PATH + '/leads';
export const LEAD_DETAIL_PATH = BASE_PATH + '/leads/:id/detail';

export const PATIENTS_PATH = BASE_PATH + '/patients';
export const PATIENT_DETAIL_PATH = BASE_PATH + '/patients/:id/detail';
export const PATIENT_EDIT_PATH = BASE_PATH + '/patients/:id/edit';
export const PATIENT_CREATE_PATH = BASE_PATH + '/patients/create';

export const ORDERS_PATH = BASE_PATH + '/orders';

export const CASES_PATH = BASE_PATH + '/cases';
export const CASE_DETAIL_PATH = BASE_PATH + '/cases/:id/detail';

export const STATUSES_PATH = BASE_PATH + '/statuses';
export const STATUS_EDIT_PATH = BASE_PATH + '/statuses/:id/edit';

export const PRODUCTS_PATH = BASE_PATH + '/products';

export const SUBSCRIPTIONS_PATH = BASE_PATH + '/subscriptions';

export const USERS_PATH = BASE_PATH + '/users';

export const TICKETS_PATH = BASE_PATH + '/tickets';

export const QUESTIONNAIRES_PATH = BASE_PATH + '/questionnaires';

export const VOUCHERS_PATH = BASE_PATH + '/vouchers';
