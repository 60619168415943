import { Col, Empty, Flex, Row } from 'antd';
import React, { Fragment } from 'react';
import styles from './LeadPrescription.module.scss';

const PrescriptionCard = ({ prescription = {} }) => {
  return (
    <Flex vertical className={styles.prescriptionCardContainer} gap={20}>
      <Flex vertical gap={6}>
        <div className="text-2">Name</div>
        <div className="subtitle-1">{prescription?.name}</div>
      </Flex>
      <Flex vertical gap={6}>
        <div className="text-2">Directions</div>
        <div className="text-1">{prescription?.directions}</div>
      </Flex>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">Days Supply</div>
          <div className="text-1">{prescription?.days_supply}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-2">Pharmacy</div>
          <div className="text-1">{prescription?.pharmacy_name}</div>
        </Col>
      </Row>
    </Flex>
  );
};

const LeadPrescriptionsBody = ({ prescriptionData = [] }) => {
  return (
    <Flex vertical className={styles.prescriptionsBodyContainer} style={{ minHeight: '100%' }} gap={24}>
      {prescriptionData?.length > 0 ? (
        prescriptionData?.map((prescription) => <PrescriptionCard key={prescription?.id} prescription={prescription} />)
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default LeadPrescriptionsBody;
