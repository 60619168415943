import { Empty, Flex } from 'antd';
import React from 'react';
import DetailCard from '../../detail-card/DetailCard';
import StatusButton from '../../../utils/status/status_button';

const PatientContact = ({ patientData = {} }) => {
  const getCardRowData = (contactItem = {}) => {
    return [
      {
        label: 'Source Id',
        value: contactItem?.source_id ?? '-',
      },
      {
        label: 'Phone',
        value: contactItem?.phone_number ?? '-',
      },
      {
        label: 'Email',
        value: contactItem?.email ?? '-',
      },
      {
        label: 'Is Primary',
        value: contactItem?.primary ? 'True' : 'False',
      },
      {
        label: 'Is Fixed',
        value: contactItem?.is_fixed ? 'True' : 'False',
      },
    ];
  };

  const getCardTitle = (contactItem) => `ID ${contactItem?.id}`;

  const getTitleExtraItems = (contactItem = {}) => {
    return (
      <Flex gap={10} style={{ overflowX: 'auto' }}>
        {contactItem?.type && <StatusButton status={contactItem.type} />}
      </Flex>
    );
  };

  return (
    <Flex vertical gap={15}>
      {patientData?.contacts?.length ? (
        patientData?.contacts?.map((contactData) => (
          <DetailCard
            key={contactData?.id}
            textToCopy={contactData?.id}
            cardRowData={getCardRowData(contactData)}
            cardTitle={getCardTitle(contactData)}
            CardTitleExtraContent={getTitleExtraItems(contactData)}
          />
        ))
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default PatientContact;
