import React, { forwardRef, useImperativeHandle } from 'react';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { deleteUserApi } from '../../../api/users-api';

const DeleteUserModalBody = forwardRef(({ userId = '', onSuccess = () => {}, setIsLoading = () => {} }, ref) => {
  const onSubmit = async () => {
    if (!userId) return toast.error('Can not delete user, Corrupt user data!');
    setIsLoading(true);
    try {
      const res = await deleteUserApi(userId);
      if (res && res?.status) {
        toast.success('User deleted successfully');
        onSuccess();
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm() {
      onSubmit();
    },
  }));

  return <div className="subtitle-2">{'Are you sure you want to delete this user!'}</div>;
});

export default DeleteUserModalBody;
