import React from 'react';
import { DataTable } from '../..';
import { useMetricsData } from '../../../hooks';
import { DASHBOARD_SUB_TAB_SLUGS } from '../../../constants/dashboard-constants';

const MetricTable = ({ columns = [], dashboardType = '' }) => {
  const { loading, metricsData, pending } = useMetricsData({
    dashboardType: dashboardType,
    subDashboardType: DASHBOARD_SUB_TAB_SLUGS.TABLE,
  });

  return (
    <DataTable
      //data
      columns={columns}
      tableData={metricsData ?? []}
      pagination={false}
      progressPending={loading || pending}
    />
  );
};

export default MetricTable;
