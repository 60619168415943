import React, { Fragment } from 'react';
import { Badge, Sidebar } from '../../../common';
import { Flex } from 'antd';
import UserDevicesBody from './UserDevicesBody';

const UserDevicesSidebar = ({
  userId = '',
  sidebarDescription,
  devicesData = [],
  devicesSidebarOpen = false,
  setDevicesSidebarOpen = () => {},
  onSuccess = () => {},
}) => {
  const totalDevices = devicesData?.length ?? 0;

  const getDevicesTitle = () => (
    <Flex align="center" gap={10}>
      <span>{'User Devices'}</span>
      <Badge count={totalDevices} />
    </Flex>
  );

  return (
    <Fragment>
      <Sidebar
        sidebarOpen={devicesSidebarOpen}
        setSidebarOpen={setDevicesSidebarOpen}
        heading={getDevicesTitle()}
        description={sidebarDescription}
        content={<UserDevicesBody devicesData={devicesData} onSuccess={onSuccess} />}
        destroyOnClose={true}
      />
    </Fragment>
  );
};

export default UserDevicesSidebar;
