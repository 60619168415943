import { Fragment } from 'react';
import { DASHBOARD_TAB_SLUGS } from '../../../constants/dashboard-constants';
import { Flex } from 'antd';
import { ORDER_METRICS_COLUMNS } from './OrderMetricConstants';
import MetricStats from '../metric-stats/MetricStats';
import MetricGraph from '../metric-graph/MetricGraph';
import MetricTable from '../metric-table/MetricTable';

const OrderMetrics = () => {
  const scale = {
    y: {
      type: 'log',
      base: 4,
      domainMin: 1,
      clamp: true,
      nice: true,
    },
  };

  return (
    <Fragment>
      <Flex vertical gap={20}>
        <MetricStats dashboardType={DASHBOARD_TAB_SLUGS.ORDERS_TAB} />
        <MetricGraph title="Order Trends" dashboardType={DASHBOARD_TAB_SLUGS.ORDERS_TAB} scale={scale} />
        <MetricTable dashboardType={DASHBOARD_TAB_SLUGS.ORDERS_TAB} columns={ORDER_METRICS_COLUMNS} />
      </Flex>
    </Fragment>
  );
};

export default OrderMetrics;
