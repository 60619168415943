export const PRODUCT_METRICS_COLUMNS = [
  {
    name: 'ID',
    selector: (row) => row.id,
    cell: (row) => <div style={{ whiteSpace: 'pre-wrap' }}>{row.id}</div>,
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    cell: (row) => <div style={{ whiteSpace: 'pre-wrap' }}>{row.name}</div>,
  },
  {
    name: 'Type',
    selector: (row) => row.type,
    cell: (row) => <div>{row?.type ? row.type : '-'}</div>,
  },
  {
    name: 'Price',
    selector: (row) => row.price,
    cell: (row) => <div>{row?.price ? row.price : '-'}</div>,
  },
  {
    name: 'Sales Margin',
    selector: (row) => row.sales_margin,
    cell: (row) => <div>{row?.sales_margin ? row.sales_margin : '-'}</div>,
  },
  {
    name: 'Total Orders',
    selector: (row) => row.total_orders,
    cell: (row) => <div>{row?.total_orders ? row.total_orders : '-'}</div>,
  },
  {
    name: 'Total Refund',
    selector: (row) => row.total_refund,
    cell: (row) => <div>{row?.total_refund ? row.total_refund : '-'}</div>,
  },
  {
    name: 'Net Revenue',
    selector: (row) => row.net_profit,
    cell: (row) => <div>{row?.net_revenue ? row.net_revenue : '-'}</div>,
  },
  {
    name: 'Gross Revenue',
    selector: (row) => row.gross_profit,
    cell: (row) => <div>{row?.gross_revenue ? row.gross_revenue : '-'}</div>,
  },
];
