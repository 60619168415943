import React from 'react';
import styles from './ListingLoader.module.scss';

const ListingLoader = ({ loaderWidth = '100px', loaderHeight = '100px' }) => {
  return (
    <div
      style={{ '--loader-width': loaderWidth, '--loader-height': loaderHeight }}
      className={styles.loaderContainer}
    ></div>
  );
};

export default ListingLoader;
