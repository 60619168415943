import React from 'react';
import { Tabs as AntdTabs } from 'antd';
import styles from './VerticalTabs.module.scss';

const VericalTabs = ({
  tabsList = [],
  tabStyle = {},
  defaultActiveKey = '1',
  tabPosition = 'left',
  onTabClick = () => {},
  containerHeight = '',
  ...rest
}) => {
  const customHeight = containerHeight ? containerHeight : '700px';
  const renderTabBar = (props, DefaultTabBar) => <DefaultTabBar {...props} />;

  return (
    <div className={styles.tabsContainer}>
      <AntdTabs
        defaultActiveKey={String(defaultActiveKey)}
        style={{ '--custom-height': customHeight, ...tabStyle }}
        tabPosition={tabPosition}
        onTabClick={onTabClick}
        renderTabBar={renderTabBar}
        items={tabsList
          ?.filter((item) => item?.show)
          ?.map((tab) => {
            return {
              label: tab.label,
              key: String(tab.key),
              children: tab.children,
            };
          })}
        {...rest}
      />
    </div>
  );
};

export default VericalTabs;
