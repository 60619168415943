import { fetchValueByDotOperator } from '../../../utils';

export const ORDER_METRICS_COLUMNS = [
  {
    name: 'First Order At',
    selector: (row) => row.first_order_at,
    cell: (row) => <div style={{ whiteSpace: 'pre-wrap' }}>{row?.first_order_at ? row.first_order_at : '-'}</div>,
  },
  {
    name: 'New Customers',
    selector: (row) => row.new_customers,
    cell: (row) => <div style={{ whiteSpace: 'pre-wrap' }}>{row?.new_customers ? row.new_customers : '-'}</div>,
  },
  {
    name: 'Retention Rate',
    selector: (row) => row.retention_rate,
    cell: (row) => <div style={{ whiteSpace: 'pre-wrap' }}>{row?.retention_rate ? row.retention_rate : '-'}</div>,
  },
  {
    name: 'First Orders',
    selector: (row) => row.first_order,
    cell: (row) => <div>{row?.first_order ? row.first_order : '-'}</div>,
  },
  {
    name: '0',
    selector: (row) => fetchValueByDotOperator(row, '0'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '0') ? fetchValueByDotOperator(row, '0') : '-'}</div>,
  },
  {
    name: '1',
    selector: (row) => fetchValueByDotOperator(row, '1'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '1') ? fetchValueByDotOperator(row, '1') : '-'}</div>,
  },
  {
    name: '2',
    selector: (row) => fetchValueByDotOperator(row, '2'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '2') ? fetchValueByDotOperator(row, '2') : '-'}</div>,
  },
  {
    name: '3',
    selector: (row) => fetchValueByDotOperator(row, '3'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '3') ? fetchValueByDotOperator(row, '3') : '-'}</div>,
  },
  {
    name: '4',
    selector: (row) => fetchValueByDotOperator(row, '4'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '4') ? fetchValueByDotOperator(row, '4') : '-'}</div>,
  },
  {
    name: '5',
    selector: (row) => fetchValueByDotOperator(row, '5'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '5') ? fetchValueByDotOperator(row, '5') : '-'}</div>,
  },
  {
    name: '6',
    selector: (row) => fetchValueByDotOperator(row, '6'),
    cell: (row) => <div>{fetchValueByDotOperator(row, '6') ? fetchValueByDotOperator(row, '6') : '-'}</div>,
  },
];
