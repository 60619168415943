import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import themeConfig from './configs/antd.theme.config';
import { ConfigProvider as ReactAvatarConfigProvider } from 'react-avatar';
import { defaultAvatarColors } from './constants/generic-lists';
import ElevateRoutes from './router';
import './App.css';
import './styles/utils.scss';
import './styles/antd.customize.scss';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AntdConfigProvider theme={themeConfig}>
          <ReactAvatarConfigProvider colors={defaultAvatarColors}>
            <ElevateRoutes />
          </ReactAvatarConfigProvider>
        </AntdConfigProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

