import React, { Fragment } from 'react';
import NotFound from '../pages/404/not_found';
import Signin from '../pages/auth/signin';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { BASE_PATH, UP_PATH } from '../constants/admin-paths';
import ProtectedRoute from '../components/protected-route/ProtectedRoute';
import TestRoute from '../pages/test-route/TestRoute';
import ElevateAdminRoutes from './ElevateAdminRoutes';
import { getCookie } from '../utils/cookies-util';

const BaseRoutes = ({ user = {}, handleLogin = () => {}, handleLogut = () => {} }) => {
  const location = useLocation();
  const validToken = getCookie('access-token');
  const isUpPath = location.pathname === UP_PATH;
  const isBasePath = location.pathname.split('/')?.[1] === 'admin';

  return (
    <Routes>
      {isUpPath ? (
        <Route path={UP_PATH} element={<TestRoute />} />
      ) : isBasePath ? (
        <Fragment>
          <Route
            path="/admin/signin"
            element={user && validToken ? <Navigate to={BASE_PATH} /> : <Signin handleLogin={handleLogin} />}
          />
          <Route
            path="/*"
            element={
              <ProtectedRoute user={user}>
                <ElevateAdminRoutes user={user} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Fragment>
      ) : (
        <Route path="*" element={<NotFound />} />
      )}
    </Routes>
  );
};

export default BaseRoutes;
