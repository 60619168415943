import React from 'react';
import { Dropdown as AntdDropdown, Space } from 'antd';
import Button from '../button/Button';

//Refactor this component more to accept generic dropdown button else show default button
const Dropdown = ({
  items,
  style,
  onClick,
  defaultBtnSize = 'medium',
  triggerMode = ['click'],
  placement = 'bottomRight',
  DropDownButton,
  ...rest
}) => (
  <AntdDropdown
    menu={{
      items,
    }}
    style={style}
    trigger={triggerMode}
    placement={placement}
    {...rest}
  >
    <a onClick={onClick}>
      {DropDownButton ? (
        <DropDownButton onClick={(e) => e.preventDefault()} />
      ) : (
        <Button type={'link'} icon={'ActionsIcon'} size={defaultBtnSize} onClick={(e) => e.preventDefault()} />
      )}
    </a>
  </AntdDropdown>
);
export default Dropdown;
