import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchOrdersApi } from '../../api/leads-api';

const initialState = {
  activeColumns: [],
  orders: [],
  statuses: [],
  products: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchOrdersListing = createAsyncThunk('orders/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'updated_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchOrdersApi(paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const OrdersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setOrdersActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrdersListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchOrdersListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.orders = action?.payload?.data || [];
        state.statuses = action?.payload?.order_statuses || [];
        state.products = action?.payload?.products || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchOrdersListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setOrdersLoading, setOrdersActiveColumn } = OrdersSlice.actions;
export default OrdersSlice.reducer;
