import React, { Fragment, Suspense, useEffect } from 'react';
import AdminDashboardLayout from '../admin-dashboard/AdminDashboardLayout';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissions } from '../../reducers/permissions-slice';
import { selectPermissions } from '../../reducers/selectors';
import { isEmptyObject } from '../../utils';
import { ListingLoader } from '../../common';

const Layout = ({ user }) => {
  const dispatch = useDispatch();
  const { permissions, loading } = useSelector(selectPermissions);

  useEffect(() => {
    user && isEmptyObject(permissions) && dispatch(fetchPermissions());
  }, []);

  if (loading) return <ListingLoader loaderHeight="200px" loaderWidth="200px" />;

  return (
    <Fragment>
      <AdminDashboardLayout>
        <Suspense fallback={<> </>}>
          <Outlet />
        </Suspense>
      </AdminDashboardLayout>
    </Fragment>
  );
};

export default Layout;
