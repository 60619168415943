import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchStatusesApi } from '../../api/statuses-api';

const initialState = {
  activeColumns: [],
  statuses: [],
  types: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchStatusesListing = createAsyncThunk('statuses/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'updated_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchStatusesApi(paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const StatusesSlice = createSlice({
  name: 'statuses',
  initialState,
  reducers: {
    setStatusesLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setStatusesActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStatusesListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchStatusesListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.statuses = action?.payload?.data || [];
        state.types = action?.payload?.status_types || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchStatusesListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setStatusesActiveColumn, setStatusesLoading } = StatusesSlice.actions;
export default StatusesSlice.reducer;
