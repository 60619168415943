import APIController from '../core-api-controller';

//Listing's Api

export const fetchUsersApi = (paramsObj = {}, paramsStr = '') => {
  //users table list
  const path = `${APIController.endpointApi}/users${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const fetchUsersListApi = (paramsObj = {}, paramsStr = '') => {
  //optimized users list
  const path = `${APIController.endpointApi}/users/list${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const createUserApi = (data = {}) => {
  const path = `${APIController.endpointApi}/users`;
  return APIController.postCallback(path, data);
};

export const updateUserApi = (data = {}, userId = '') => {
  const path = `${APIController.endpointApi}/users/${userId}`;
  return APIController.putCallback(path, data);
};

export const deleteUserApi = (userId = '') => {
  const path = `${APIController.endpointApi}/users/${userId}/deactivate_account`;
  return APIController.putCallback(path);
};

export const userDeviceRevokeApi = (deviceId = '') => {
  const path = `${APIController.endpointApi}/user_devices/${deviceId}/revoke`;
  return APIController.putCallback(path);
};

export const userDeviceUnRevokeApi = (deviceId = '') => {
  const path = `${APIController.endpointApi}/user_devices/${deviceId}/unrevoke`;
  return APIController.putCallback(path);
};

//user columns api

export const updateUserColumnsApi = (data = {}) => {
  const path = `${APIController.endpointApi}/users/update_user_columns`;
  return APIController.postCallback(path, data);
};
