import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchPatientsApi } from '../../api/patients-api';

const initialState = {
  activeColumns: [],
  patients: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchPatientsListing = createAsyncThunk('patients/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'updated_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchPatientsApi(paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const PatientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatientsLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setPatientsActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPatientsListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchPatientsListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.patients = action?.payload?.data || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchPatientsListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setPatientsLoading, setPatientsActiveColumn } = PatientsSlice.actions;
export default PatientsSlice.reducer;
