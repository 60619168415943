import React, { useRef } from 'react';
import { Layout } from 'antd';
import SideMenu from '../side-menu/SideMenu';
const { Content } = Layout;

const AdminDashboardLayout = ({ children }) => {
  const scrollRef = useRef(null);

  return (
    <Layout>
      <Layout>
        <SideMenu />
        <Layout
          style={{
            overflowX: 'hidden',
          }}
        >
          <Content className="main-content" ref={scrollRef}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AdminDashboardLayout;
