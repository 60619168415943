import { Empty, Flex } from 'antd';
import React from 'react';

const TableEmpty = () => {
  return (
    <Flex justify="center" align="center" style={{ minHeight: '250px' }}>
      <Empty />
    </Flex>
  );
};

export default TableEmpty;
