import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchVouchersApi } from '../../api/vouchers-api';

const initialState = {
  vouchers: [],
  activeColumns: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchVouchersListing = createAsyncThunk('vouchers/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'updated_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchVouchersApi('', paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const VouchersSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setVouchersLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setVouchersActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVouchersListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchVouchersListing.fulfilled, (state, action) => {
        if (state.loading) {
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.vouchers = action?.payload?.data || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchVouchersListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setVouchersLoading, setVouchersActiveColumn } = VouchersSlice.actions;
export default VouchersSlice.reducer;
