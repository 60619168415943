import { Formik } from 'formik';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { Flex } from 'antd';
import { Input, Switch } from '../../../common';
import { editProductsApi } from '../../../api/products-api';

const ProductEditModalBody = forwardRef(
  ({ productId = '', productData = {}, setIsLoading = () => {}, onSuccess = () => {} }, ref) => {
    const formikRef = useRef();

    const getInitialValues = () => {
      return {
        display_name: productData?.display_name ?? '',
        is_refill: productData?.is_refill ?? false,
        is_active: productData?.active ?? false,
        cost: productData?.cost ?? '',
      };
    };

    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef?.current?.submitForm();
      },
    }));

    const handleSubmit = async (values) => {
      if (!productId) return;
      setIsLoading(true);
      try {
        const payload = {
          product: {
            display_name: values?.display_name,
            active: values?.is_active,
            is_refill: values?.is_refill,
            cost: values?.cost,
          },
        };
        const res = await editProductsApi(productId, payload);
        if (res && res?.status) {
          onSuccess();
        } else {
          toast.error(getErrorString(res));
        }
      } catch (error) {
        toast.error(getErrorString(error));
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Formik innerRef={formikRef} initialValues={getInitialValues()} onSubmit={handleSubmit}>
        {(formikProps) => {
          const { values, handleSubmit, handleChange, setFieldValue, touched, errors, isSubmitting } = formikProps;
          return (
            <form>
              <Flex vertical gap={25}>
                <Input
                  placeholder="Enter Display Name"
                  name="display_name"
                  label="Display Name"
                  value={values?.display_name}
                  errorMsg={errors?.display_name && touched?.display_name && errors?.display_name}
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                />

                <Input
                  placeholder="Enter Cost"
                  name="cost"
                  label="Cost"
                  value={values?.cost}
                  errorMsg={errors?.cost && touched?.cost && errors?.cost}
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                />

                <Switch
                  label={'Is Active'}
                  checked={values?.is_active}
                  handleChange={(toggle) => setFieldValue('is_active', toggle)}
                  loading={isSubmitting}
                />

                <Switch
                  label={'Is Refill'}
                  checked={values?.is_refill}
                  handleChange={(toggle) => setFieldValue('is_refill', toggle)}
                  loading={isSubmitting}
                />
              </Flex>
            </form>
          );
        }}
      </Formik>
    );
  },
);

export default ProductEditModalBody;
