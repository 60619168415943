import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';

const Tooltip = ({
  title = '',
  children,
  overlayClassName,
  overlayStyle,
  widthAuto,
  minWidth,
  show = true,
  color,
  ...rest
}) => {
  if (!show) return <>{children}</>;

  return (
    <AntdTooltip title={title} color={color ? color : '#222222'} overlayStyle={overlayStyle} {...rest}>
      <div> {children} </div>
    </AntdTooltip>
  );
};

export default Tooltip;
