import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DASHBOARD_TAB_SLUGS } from '../../constants/dashboard-constants';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { appendParamsToQueryString } from '../../utils/filters-util';
import { fetchDashboardDataApi } from '../../api/dashboard-api';

const useMetricsData = ({ dashboardType = DASHBOARD_TAB_SLUGS.PERFORMANCE_TAB, subDashboardType = '' }) => {
  const location = useLocation();
  const [metricsData, setMetricsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const fetchData = async () => {
    setPending(true);
    try {
      var updatedSearchQuery = appendParamsToQueryString('tab', dashboardType, location.search);
      updatedSearchQuery = !!subDashboardType
        ? appendParamsToQueryString('subtab', subDashboardType, updatedSearchQuery)
        : updatedSearchQuery;
      const res = await fetchDashboardDataApi(updatedSearchQuery);
      if (res && res?.status) {
        setMetricsData(res?.data ?? {});
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
      setPending(false);
    }
  };

  useEffect(() => {
    if (location.search) {
      fetchData();
    }
  }, [location.search]);

  return {
    metricsData,
    loading,
    pending,
  };
};

export default useMetricsData;
