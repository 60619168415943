import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { handleFilterChips } from '../../utils/filter-chips-util';
import { mapQueryStringToFilterObject } from '../../utils/filters-util';

const useFilterChips = ({ dependencies = [], filtersList = [] }) => {
  const location = useLocation();
  const queryObj = mapQueryStringToFilterObject(location.search || '');
  const filtersObject = { ...queryObj?.queryObj };
  const [filterChips, setFilterChips] = useState([]);

  useEffect(() => {
    setFilterChips(handleFilterChips({ filtersList: filtersList, location: location, filtersObject: filtersObject }));
  }, [location, ...dependencies]);

  return {
    filterChips,
  };
};

export default useFilterChips;
