import React, { useEffect, useState } from 'react';
import { Col, Empty, Flex, Row } from 'antd';
import styles from './LeadContact.module.scss';
import DisplayDateTime from '../../../utils/time/display_time';
import StatusButton from '../../../utils/status/status_button';
import { Switch } from '../../../common';
import EditPatientContactModal from '../../modals/patient-contact-modal/EditPatientContactModal';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';
import DeletePatientContactModal from '../../modals/patient-contact-modal/DeletePatientContactModal';
import { markFixedPatientContactApi, markUnFixedPatientContactApi } from '../../../api/patients-api';
import { getErrorString } from '../../../utils';
import toast from 'react-hot-toast';

const ContactCard = ({ patientContact = {}, onSuccessEdit = () => {} }) => {
  const [isFixed, setIsFixed] = useState(patientContact?.is_fixed ?? false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsFixed(patientContact?.is_fixed ?? false);
  }, [patientContact?.is_fixed]);

  const handleContactFixedChange = async (toggle) => {
    setIsFixed(toggle);
    setLoading(true);
    const updateFixedContactApi = toggle ? markFixedPatientContactApi : markUnFixedPatientContactApi;
    try {
      const res = await updateFixedContactApi(patientContact?.id);
      if (res && res?.status) {
        onSuccessEdit(res?.data?.contacts);
      } else {
        toast.error(getErrorString(res));
        setIsFixed(!toggle);
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setIsFixed(!toggle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex vertical className={styles.contactCardContainer} gap={20}>
      <Flex align="center" justify="space-between">
        <StatusButton status={patientContact?.type} />
        <Flex gap={10}>
          <Permission models={PERMISSIONS_STRUCT.CONTACT.NAME} actions={PERMISSIONS_STRUCT.CONTACT.ACTIONS.UPDATE}>
            <EditPatientContactModal
              contactId={patientContact?.id}
              contactData={patientContact}
              onSuccessEdit={onSuccessEdit}
            />
          </Permission>
          <Permission models={PERMISSIONS_STRUCT.CONTACT.NAME} actions={PERMISSIONS_STRUCT.CONTACT.ACTIONS.DESTROY}>
            <DeletePatientContactModal contactId={patientContact?.id} onSuccessDelete={onSuccessEdit} />
          </Permission>
        </Flex>
      </Flex>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">{'Source Id'}</div>
          <div className="text-1">{patientContact?.source_id ?? '-'}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-2">{'Primary'}</div>
          <div className="text-1">{patientContact?.primary ? 'True' : 'False'}</div>
        </Col>
      </Row>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">{'Email'}</div>
          <div className="text-1">{patientContact?.email ?? '-'}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-2">{'Phone'}</div>
          <div className="text-1">{patientContact?.phone_number ?? '-'}</div>
        </Col>
      </Row>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">{'Created At'}</div>
          <div className="text-1">{DisplayDateTime(patientContact?.source_created_at) ?? '-'}</div>
        </Col>
        {patientContact?.object_class && (
          <Col span={12}>
            <div className="text-2 mb-2">{patientContact.object_class}</div>
            <div className="text-1">{patientContact?.object_count ?? '-'}</div>
          </Col>
        )}
      </Row>
      <Col span={14}>
        <Switch
          labelClassName="text-2"
          label="Is Fixed"
          checked={isFixed}
          handleChange={handleContactFixedChange}
          loading={loading}
        />
      </Col>
    </Flex>
  );
};

const LeadContactSidebarBody = ({ contactsData = [], onSuccessEdit = () => {} }) => {
  return (
    <Flex vertical className={styles.contactsBodyContainer} style={{ minHeight: '100%' }} gap={24}>
      {contactsData?.length > 0 ? (
        contactsData?.map((patientContact) => (
          <ContactCard key={patientContact?.id} patientContact={patientContact} onSuccessEdit={onSuccessEdit} />
        ))
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default LeadContactSidebarBody;
