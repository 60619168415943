import React, { Fragment } from 'react';
import { DASHBOARD_TAB_SLUGS } from '../../../constants/dashboard-constants';
import { Flex } from 'antd';
import { PRODUCT_METRICS_COLUMNS } from './ProductMetricConstants';
import MetricStats from '../metric-stats/MetricStats';
import MetricGraph from '../metric-graph/MetricGraph';
import MetricTable from '../metric-table/MetricTable';

const ProductMetrics = () => {
  return (
    <Fragment>
      <Flex vertical gap={20}>
        <MetricStats dashboardType={DASHBOARD_TAB_SLUGS.PRODUCTS_TAB} />
        <MetricGraph title="Product Trends" dashboardType={DASHBOARD_TAB_SLUGS.PRODUCTS_TAB} />
        <MetricTable dashboardType={DASHBOARD_TAB_SLUGS.PRODUCTS_TAB} columns={PRODUCT_METRICS_COLUMNS} />
      </Flex>
    </Fragment>
  );
};

export default ProductMetrics;
