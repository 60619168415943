import { Card, Flex } from 'antd';
import { MultiLineGraph } from '../../../common';
import { useMetricsData } from '../../../hooks';
import { DASHBOARD_SUB_TAB_SLUGS } from '../../../constants/dashboard-constants';

const MetricGraph = ({ title = '', scale = {}, dashboardType = '' }) => {
  const { loading, metricsData, pending } = useMetricsData({
    dashboardType: dashboardType,
    subDashboardType: DASHBOARD_SUB_TAB_SLUGS.CHART,
  });

  return (
    <Card>
      <Flex vertical gap={10}>
        <div className="heading-3">{title}</div>
        <MultiLineGraph
          height={400}
          data={metricsData ?? []}
          xField={'key'}
          yField={'value'}
          field={'category'}
          colorField={'category'}
          shapeField={'smooth'}
          smooth={true}
          showLabelsOnLine={false}
          autoFitChart={true}
          adjustXAxisLabel={true}
          pointSize={0}
          scale={scale}
          pending={loading || pending}
        />
      </Flex>
    </Card>
  );
};

export default MetricGraph;
