import React, { useEffect, useState, useRef } from 'react';
import { removeAppCookies, setCookie } from '../../utils/cookies-util';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { loginApi } from '../../api/auth-api';
import { Card, Input, Button, ListingLoader } from '../../common';
import { Flex } from 'antd';
import cx from 'clsx';
import styles from './signin.module.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import { getErrorString } from '../../utils';
import landing from '../../assets/imgs/landing.jpg';
import logo from '../../assets/logos/logo_round.png';
import { persistor } from '../../store';
import { setUser } from '../../reducers/user-slice';
import { useDispatch } from 'react-redux';
import { BASE_PATH } from '../../constants/admin-paths';

export default function Signin({ handleLogin }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [screenloader, setScreenloader] = useState(false);
  const formikRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const fromPage =
    location.state?.from?.pathname && location.state.from.pathname !== '/' ? location.state.from.pathname : BASE_PATH;

  useEffect(() => {
    removeAppCookies();
    persistor.purge();
  }, []);

  const getValidationSchema = () =>
    yup.object().shape({
      email: yup.string().required('Email is required').email('Valid Email is required'),
      password: yup.string().required('Password is required'),
    });

  const getInitialValues = () => ({
    email: '',
    password: '',
  });

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    try {
      const data = { user: { email: values?.email || '', password: values?.password || '' } };
      const res = await loginApi(data);
      if (res && res?.status) {
        toast.success('Login Successful!');
        dispatch(setUser(res?.data?.data ?? {}));
        setCookie('access-token', res.headers['authorization']);
        setCookie('user-type', 'admin');
        handleLogin();
        navigate(fromPage, { replace: true });
        return;
      } else {
        toast.error(res?.message ?? 'Login Failed');
        setStatus({ message: res?.message });
        return;
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setStatus({ message: getErrorString(error) });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {screenloader && <ListingLoader />}
      <div className={cx('container-fluid', styles.loginPageContainer)}>
        <div className={styles.loginPageHeader}>
          {' '}
          <img src={logo} alt="" height="150" />
        </div>
        <div className={cx('row', styles.loginPageBody)}>
          <div className="col-lg-6 d-none d-lg-flex align-items-center justify-content-center">
            <img src={landing} alt="Login Image" className="img-fluid" />
          </div>

          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <Card className={'col-10 col-md-6 col-lg-8'}>
              <Flex vertical gap={10}>
                <div className="heading-1 align-self-start">Sign In</div>
                <p className="text-1">Welcome Back! Please Enter Your Detail</p>
                <Formik
                  innerRef={formikRef}
                  initialValues={getInitialValues()}
                  validationSchema={getValidationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    status,
                    setStatus,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Flex vertical gap={30}>
                        <Input
                          label="Email Adress"
                          placeholder="Enter your Email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                          errorMsg={errors.email && touched.email && errors.email}
                        />
                        <Input
                          isPassword={true}
                          label="Password"
                          placeholder="Enter your Password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onPressEnter={handleSubmit}
                          value={values?.password}
                          errorMsg={errors.password && touched.password && errors.password}
                        />
                        <Button
                          text={'Login'}
                          type="primary"
                          size="large"
                          onClick={handleSubmit}
                          loading={isSubmitting}
                        />
                      </Flex>
                    </form>
                  )}
                </Formik>
              </Flex>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
