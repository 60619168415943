export const selectPermissions = (state) => state?.permissionsData;
export const selectUser = (state) => state?.userData?.data;
export const selectLeads = (state) => state?.leadsData;
export const selectPatients = (state) => state?.patientsData;
export const selectOrders = (state) => state?.ordersData;
export const selectCases = (state) => state?.casesData;
export const selectSubscriptions = (state) => state?.subscriptionsData;
export const selectProducts = (state) => state?.productsData;
export const selectTickets = (state) => state?.ticketsData;
export const selectQuestionnaires = (state) => state?.questionnairesData;
export const selectStatuses = (state) => state?.statusesData;
export const selectVouchers = (state) => state?.vouchersData;
export const selectUsers = (state) => state?.usersData;
export const selectQuickFilters = (state) => state?.quickLinksData;
