import React from 'react';
import { Empty, Flex } from 'antd';
import DetailCard from '../../detail-card/DetailCard';

const PatientAddress = ({ patientData = {} }) => {
  const getCardRowData = (addressItem = {}) => {
    return [
      {
        label: 'Address',
        value: addressItem?.address ?? '-',
      },
      {
        label: 'City',
        value: addressItem?.city ?? '-',
      },
      {
        label: 'Address Type',
        value: addressItem?.address_type ?? '-',
      },
      {
        label: 'Postal Code',
        value: addressItem?.postal_code ?? '-',
      },
      {
        label: 'State',
        value: addressItem?.state ?? '-',
      },
      {
        label: 'Street',
        value: addressItem?.street ?? '-',
      },
      {
        label: 'Region',
        value: addressItem?.region ?? '-',
      },
    ];
  };

  const getCardTitle = (address = {}) => {
    return `${address?.address}`;
  };

  return (
    <Flex vertical gap={15}>
      {patientData?.addresses?.length ? (
        patientData?.addresses?.map((addressData) => (
          <DetailCard
            key={addressData?.id}
            textToCopy={addressData?.address}
            cardRowData={getCardRowData(addressData)}
            cardTitle={getCardTitle(addressData)}
          />
        ))
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default PatientAddress;
