import React, { useEffect, useState } from 'react';
import { fetchLeadNotesApi, submitLeadNotesApi } from '../../../api/leads-api';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { Avatar, Button, Input, Spinner } from '../../../common';
import { Empty, Flex } from 'antd';
import DisplayDateTime from '../../../utils/time/display_time';
import styles from './LeadNotes.module.scss';
import { SOURCE_CLASS_NAMES } from '../../../constants/generic-constants';

const NoteMessage = ({ user = {}, noteCreatedAt = '', message = '' }) => {
  return (
    <Flex className={styles.noteContainer} vertical gap={15}>
      <Flex align="center" gap={5}>
        <Avatar name={user?.name} />
        <Flex vertical>
          <div className="subtitle-2">{user?.name}</div>
          <div className="text-2 ">{DisplayDateTime(noteCreatedAt)}</div>
        </Flex>
      </Flex>
      <div className="text-1">{message || ''}</div>
    </Flex>
  );
};

const LeadNotes = ({ leadId = '' }) => {
  const [notes, setNotes] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const fetchLeadNotes = async () => {
    if (!leadId) return;

    setLoading(true);
    try {
      const res = await fetchLeadNotesApi(leadId);
      if (res && res?.status) {
        setNotes(res?.data ?? []);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  const submitLeadNotes = async () => {
    if (!leadId) return;
    if (!message?.length) {
      setIsEmpty(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const payload = {
        note: {
          source_type: SOURCE_CLASS_NAMES.LEAD,
          source_id: leadId,
          message: message,
        },
      };

      const res = await submitLeadNotesApi(payload);
      if (res && res?.status) {
        await fetchLeadNotes();
        setMessage('');
      } else {
        toast.error(getErrorString(res));
        setIsEmpty(true);
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setIsEmpty(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChangeMessage = (event) => {
    setIsEmpty(false);
    setMessage(event.target?.value);
  };

  useEffect(() => {
    fetchLeadNotes();
  }, [leadId]);

  return (
    <Flex vertical gap={25} className={styles.notesContainer}>
      <div className="heading-4">{'Internal Chat'}</div>
      <Flex vertical gap={25} style={{ flexGrow: 1, overflowY: 'auto' }} justify={notes?.length ? 'normal' : 'center'}>
        {!loading ? (
          notes?.length ? (
            notes?.map((note) => (
              <NoteMessage key={note?.id} user={note?.user} noteCreatedAt={note?.created_at} message={note?.message} />
            ))
          ) : (
            <Empty />
          )
        ) : (
          <Spinner size="small" color="var(--ant-color-primary)" />
        )}
      </Flex>
      <Flex gap={15} justify="end" vertical style={{ marginTop: 'auto' }}>
        <Input
          onChange={onChangeMessage}
          containerClassName="w-100"
          rows={4}
          placeholder="Type your message"
          value={message}
          errorMsg={isEmpty}
        />
        <Flex align="center" justify="end">
          <Button
            onClick={submitLeadNotes}
            disabled={loading || submitting}
            icon={'SendArrowIcon'}
            type="primary"
            text="Submit"
            size={'medium'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LeadNotes;
