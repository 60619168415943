import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchSubscriptions } from '../../api/subscriptions-api';

const initialState = {
  activeColumns: [],
  subscriptions: [],
  statuses: [],
  productStatuses: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchSubscriptionsListing = createAsyncThunk('subscriptions/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'updated_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchSubscriptions(paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const SubscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptionsLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setSubscriptionsActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSubscriptionsListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchSubscriptionsListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.subscriptions = action?.payload?.data || [];
        state.statuses = action?.payload?.subscription_statuses || [];
        state.productStatuses = action?.payload?.products || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchSubscriptionsListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setSubscriptionsLoading, setSubscriptionsActiveColumn } = SubscriptionsSlice.actions;
export default SubscriptionsSlice.reducer;
