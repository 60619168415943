import SkeletonBody, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.module.scss';

const Skeleton = ({ themeProps, ...rest }) =>
  themeProps ? (
    <SkeletonTheme {...themeProps}>
      <SkeletonBody {...rest} />
    </SkeletonTheme>
  ) : (
    <SkeletonBody {...rest} />
  );

export default Skeleton;
