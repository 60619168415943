import React, { Fragment, useState } from 'react';
import { Button, Modal } from '../../../common';
import { addVoucherApi } from '../../../api/orders-api';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';

const AddVoucherModal = ({ orderId = '', onSuccess = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    if (!orderId) return;
    try {
      setLoading(true);
      const res = await addVoucherApi(orderId);
      if (res && res?.status) {
        res?.data && onSuccess && onSuccess(res.data);
        toast.success('Voucher Added successfully');
        setModalOpen(false);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Permission models={PERMISSIONS_STRUCT.ORDER.NAME} actions={PERMISSIONS_STRUCT.ORDER.ACTIONS.NEW_VOUCHER}>
        <Button text={'Add Voucher'} size="small" onClick={() => setModalOpen(true)} />
      </Permission>

      <Modal
        title="Add Voucher Confirmation"
        open={modalOpen}
        ModalBody={<div className="subtitle-2">{'Are you sure you want to Add Voucher'}</div>}
        handleCancel={() => setModalOpen(false)}
        handleOk={onConfirm}
        okButtonProps={{ loading }}
        okText="Confirm"
        showActions={true}
        destroyOnClose={true}
        width={500}
      />
    </Fragment>
  );
};

export default AddVoucherModal;
