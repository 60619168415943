import React from 'react';
import { Skeleton } from '../../common';

export const TableLoader = (props) => {
  const {
    rowSkeletons = [1, 2, 3, 4, 5, 6, 7, 8],
    columnSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    showCheckboxSkeleton = false,
  } = props;
  return (
    <div className="d-flex flex-column w-100" style={{ marginInlineStart: '14px' }}>
      {rowSkeletons.map((row) => (
        <div className="d-flex flex-row justify-content-between border-bottom" key={row}>
          {columnSkeletons.map((col) => (
            <div className={`ml-2 mr-2 ${row === 1 ? 'mt-2' : 'mt-1'}`} key={col}>
              <div className="d-flex flex-row mb-3 mt-1" key={col}>
                {col === 0 && showCheckboxSkeleton ? (
                  <Skeleton height={17} width={17} style={{ marginLeft: '30px' }} className="mt-3" />
                ) : (
                  <Skeleton height={21} width={90} className="ml-1 mt-3" />
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
