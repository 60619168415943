import React from 'react';
import DetailCard from '../../detail-card/DetailCard';

const PatientInfo = ({ patientData = {} }) => {
  const cardRowData = [
    {
      label: 'First Name',
      value: patientData?.first_name ?? '-',
    },
    {
      label: 'Last Name',
      value: patientData?.last_name ?? '-',
    },
    {
      label: 'Email',
      value: patientData?.email ?? '-',
    },
    {
      label: 'D.O.B',
      value: patientData?.date_of_birth ?? '-',
    },
    {
      label: 'Phone Number',
      value: patientData?.phone_number ?? '-',
    },
    {
      label: 'Weight',
      value: patientData?.weight ?? '-',
    },
    {
      label: 'Allergies',
      value: patientData?.allergies ?? '-',
    },
    {
      label: 'Current Medications',
      value: patientData?.current_medications ?? '-',
    },
    {
      label: 'Gender Label',
      value: patientData?.gender_label ?? '-',
    },
    {
      label: 'Height',
      value: patientData?.height ?? '-',
    },
    {
      label: 'Medical Conditions',
      value: patientData?.medical_conditions ?? '-',
    },
    {
      label: 'Pregnancy',
      value: patientData?.pregnancy ? 'True' : 'False',
    },
    {
      label: 'Secondary Email',
      value: patientData?.secondary_email ?? '-',
    },
    {
      label: 'Secondary Number',
      value: patientData?.secondary_phone_number ?? '-',
    },
    {
      label: 'Updated At',
      value: patientData?.updated_at ?? '-',
    },
    {
      label: 'Weight',
      value: patientData?.weight ?? '-',
    },
    {
      label: 'MDI Id',
      value: patientData?.mdi_patient_id ?? '-',
    },
    {
      label: 'Samcart Id',
      value: patientData?.samcart_customer_id ?? '-',
    },
  ];

  const getCardTitle = () => {
    return 'MDI Patient ID ' + (patientData?.mdi_patient_id ?? '-');
  };

  return (
    <DetailCard
      gridRows={true}
      textToCopy={patientData?.mdi_patient_id}
      cardTitle={getCardTitle()}
      cardRowData={cardRowData}
    />
  );
};

export default PatientInfo;
