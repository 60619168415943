import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { getCookie } from '../utils/cookies-util';
import { AuthProvider } from '../contexts/auth-context';
import { BASE_PATH } from '../constants/admin-paths';
import BaseRoutes from './BaseRoutes';

const ElevateRoutes = () => {
  const currentUser = getCookie('access-token');
  const [user, setUser] = useState(!!currentUser);
  const handleLogin = () => setUser(true);
  const handleLogout = () => setUser(false);
  return (
    <AuthProvider value={{ handleLogout }}>
      <Router>
        <Toaster />
        <BaseRoutes user={user} handleLogin={handleLogin} handleLogut={handleLogout} />
      </Router>
    </AuthProvider>
  );
};

export default ElevateRoutes;
