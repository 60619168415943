import React, { Fragment, useRef, useState } from 'react';
import { usePatientContactsContext } from '../../../contexts/patient-contact-context';
import { Button, Modal } from '../../../common';
import { editPatientContactApi } from '../../../api/patients-api';
import PatientContactModalBody from './PatientContactModalBody';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';

const EditPatientContactModal = ({ contactId = '', contactData = {}, onSuccessEdit = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { contactTypes } = usePatientContactsContext();
  const formRef = useRef();

  const handleSubmit = async (values) => {
    if (!values?.type?.id || !contactId) return;
    try {
      setLoading(true);
      const payload = {
        contact: {
          type_id: values?.type?.id,
          source_id: values?.id,
        },
      };

      const res = await editPatientContactApi(contactId, payload);
      if (res && res?.status) {
        res?.data?.contacts && onSuccessEdit(res?.data?.contacts);
        toast.success('Contact Edited Successfully');
        setModalOpen(false);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button icon={'CiEdit'} size={'small'} onClick={() => setModalOpen(true)} />

      <Modal
        title="Edit Contact"
        open={modalOpen}
        ModalBody={
          <PatientContactModalBody
            ref={formRef}
            handleSubmit={handleSubmit}
            patientContactTypes={contactTypes}
            contactData={contactData}
            isEdit={true}
          />
        }
        handleCancel={() => setModalOpen(false)}
        handleOk={() => {
          formRef?.current?.submitForm();
        }}
        okButtonProps={{ loading }}
        okText="Submit"
        showActions={true}
        destroyOnClose={true}
        width={700}
      />
    </Fragment>
  );
};

export default EditPatientContactModal;
