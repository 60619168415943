import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getCookie } from '../../utils/cookies-util';
import { SIGNIN_PATH } from '../../constants/admin-paths';

const ProtectedRoute = ({ user, children }) => {
  const validToken = getCookie('access-token');
  const location = useLocation();

  return user && validToken ? children : <Navigate to={SIGNIN_PATH} state={{ from: location }} replace={true} />;
};

export default ProtectedRoute;
