import React from 'react';
import Card from '../../common/card/Card';
import { Badge, Button, CopyToClipboard } from '../../common';
import { Col, Flex, Row } from 'antd';
import StatusButton from '../../utils/status/status_button';
import styles from './DetailCard.module.scss';

const DetailCardRowItem = ({ label = '', value = '' }) => {
  return (
    <Col span={24}>
      <div className={styles.labelValueGrid}>
        <div className="text-2 ">{label}</div>
        <div>{value}</div>
      </div>
    </Col>
  );
};

const DetailCard = ({
  cardRowData = [],
  cardTitle = '',
  CardTitleExtraContent = () => {},
  CardExtraContent = () => {},
  CardActionContent = () => {},
  textToCopy = '',
  gridRows = false,
}) => {
  const title = (
    <Flex align="center" gap={10}>
      <span className="subtitle-1 text-ellipsis" style={{ '--ellipsis-max-ch': '16ch' }}>
        {cardTitle}
      </span>
      {textToCopy && <CopyToClipboard value={textToCopy} />}
      {CardTitleExtraContent && <div>{CardTitleExtraContent}</div>}
    </Flex>
  );

  return (
    <Card headerTitle={title} Extra={CardExtraContent} bordered>
      {gridRows ? (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            {cardRowData?.map((rowData, index) => (
              <Col span={12} key={rowData?.label || index}>
                <DetailCardRowItem label={rowData?.label} value={rowData?.value} />
              </Col>
            ))}
          </Row>
        </Col>
      ) : (
        <Row justify="space-between" align={'bottom'}>
          <Col span={12}>
            <Flex vertical gap={14}>
              {cardRowData?.map((rowData) => (
                <DetailCardRowItem key={rowData?.label} label={rowData?.label} value={rowData?.value} />
              ))}
            </Flex>
          </Col>
          <Col style={{ textAlign: 'right', marginTop: '5px' }}>{CardActionContent}</Col>
        </Row>
      )}
    </Card>
  );
};

export default DetailCard;
