import './not_found.css';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logos/logo_round.png';
import { BASE_PATH } from '../../constants/admin-paths';

export default function NotFound() {
  return (
    <div className="Center_NotFound">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="heading-1">Not Found</div>
      <br />
      <div className="heading-1 mb-2">This Path Doesn't Exist.</div>
      <Link className="link-text" to={BASE_PATH}>
        Go To HomePage
      </Link>
    </div>
  );
}
