import React, { Fragment, useState } from 'react';
import { Badge, Button, Sidebar, TextWithCopy } from '../../../common';
import { Col, Empty, Flex, Row } from 'antd';
import { isEmptyArray, isFieldRestricted } from '../../../utils';
import styles from './LeadVouchers.module.scss';
import DisplayDateTime from '../../../utils/time/display_time';

const VoucherCard = ({ voucher = {} }) => {
  return (
    <Flex vertical className={styles.vouchersCardContainer} gap={20}>
      <Flex vertical gap={6}>
        <div className="text-2">{'Onboarding Url'}</div>
        <TextWithCopy value={voucher?.onboarding_url} />
      </Flex>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-3">{'Is Expired'}</div>
          <div className="subtitle-2">{String(voucher?.is_expired) ?? '-'}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-3">{'Expires At'}</div>
          <div className="subtitle-2">{DisplayDateTime(voucher?.expires_at) ?? '-'}</div>
        </Col>
      </Row>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-3">{'Used At'}</div>
          <div className="subtitle-2">{DisplayDateTime(voucher?.used_at) ?? '-'}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-3">{'MDI created At'}</div>
          <div className="subtitle-2">{DisplayDateTime(voucher?.mdi_created_at) ?? '-'}</div>
        </Col>
      </Row>
    </Flex>
  );
};

const VouchersContent = ({ vouchersData = [] }) => (
  <Flex vertical className={styles.vouchersBodyContainer} style={{ minHeight: '100%' }} gap={24}>
    {vouchersData?.length > 0 ? (
      vouchersData?.map((voucher) => <VoucherCard key={voucher?.id} voucher={voucher} />)
    ) : (
      <Empty />
    )}
  </Flex>
);

const LeadVouchers = ({ vouchers = [], vouchersDescription = '' }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const vouchersCount = vouchers?.length ?? 0;

  const getVouchersTitle = () => (
    <Flex align="center" gap={10}>
      <span>{'Vouchers'}</span>
      <Badge count={vouchersCount} />
    </Flex>
  );

  if (isFieldRestricted(vouchers) || isEmptyArray(vouchers)) return null;

  return (
    <Fragment>
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        heading={getVouchersTitle()}
        description={vouchersDescription}
        content={<VouchersContent vouchersData={vouchers} />}
      />

      <Button size={'large'} onClick={() => setSidebarOpen((prevState) => !prevState)}>
        {'View Vouchers'}
        <Badge count={vouchersCount} />
      </Button>
    </Fragment>
  );
};

export default LeadVouchers;
