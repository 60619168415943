import React from 'react';
import cx from 'clsx';
// import PropTypes from 'prop-types';
import styles from './status.module.scss';

const StatusButton = ({ status }) => {
  return (
    <button
      className={cx(styles.statusButton)}
      style={{
        '--bg-color': status?.bg_color ?? 'var(--gray-100)',
        '--color': status?.color,
        wordBreak: 'keep-all',
      }}
    >
      {status?.name}
    </button>
  );
};

export default StatusButton;
