import { Col, Row } from 'antd';
import React from 'react';
import StatsCard from '../stats-card/StatsCard';
import { useMetricsData } from '../../../hooks';
import { isEmptyArray } from '../../../utils';
import { DASHBOARD_SUB_TAB_SLUGS, DASHBOARD_TAB_SLUGS } from '../../../constants/dashboard-constants';

const MetricStats = ({ dashboardType = DASHBOARD_TAB_SLUGS.ORDERS_TAB }) => {
  const { loading, metricsData, pending } = useMetricsData({
    dashboardType: dashboardType,
    subDashboardType: DASHBOARD_SUB_TAB_SLUGS.STATS,
  });

  if (isEmptyArray(metricsData)) return null;

  return (
    <Row gutter={[8, 10]} className="d-flex w-100" wrap>
      {loading
        ? [0, 1, 2, 3].map((index) => (
            <Col xs={24} sm={12} md={6} lg={6} xl={6} key={index}>
              <StatsCard pending={loading} />
            </Col>
          ))
        : metricsData?.map((stat, index) => (
            <Col xs={24} sm={12} md={6} lg={6} xl={6} key={index}>
              <StatsCard stat={stat} pending={pending} />
            </Col>
          ))}
    </Row>
  );
};

export default MetricStats;
