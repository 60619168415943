import { Flex } from 'antd';
import React from 'react';
import ManageColumnDropDown from '../manage-column-dropdown/ManageColumnDropDown';

const ListingHeader = ({
  headerTitle = '',
  sourceClass = '',
  activeColumns = [],
  onDropDownClose = () => {},
  ExtraActions = () => {},
  showManageColumns = true,
}) => {
  return (
    <Flex align="center" justify="space-between">
      <div className="heading-1">{headerTitle}</div>
      <Flex gap={10}>
        {showManageColumns && (
          <ManageColumnDropDown
            sourceClass={sourceClass}
            columnOptions={activeColumns}
            onDropDownClose={(updatedColumns) => onDropDownClose(updatedColumns)}
          />
        )}
        {ExtraActions && ExtraActions}
      </Flex>
    </Flex>
  );
};

export default ListingHeader;
