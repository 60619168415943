import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../utils/filters-util';
import RenderFilters from '../../components/filters/render-filters/RenderFilters';
import { isEmptyObject } from '../../utils';

const DateFilter = ({
  dateItem = {
    type: 'date',
    key: '',
    placeholder: '',
    showRangePreset: true,
  },
  loading = false,
  defaultFilters = {},
  fetchData = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filtersObject, setFiltersObject] = useState({});

  const loadData = () => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj };
    setFiltersObject(updatedFilterObject);
    isEmptyObject(updatedFilterObject) && fetchData ? fetchData() : fetchData(updatedFilterObject);
  };

  const handleFiltersChange = (updatedFilterObject) => {
    navigate({
      pathname: location.pathname,
      search: convertQueryObjToString(updatedFilterObject),
    });
  };

  useEffect(() => {
    loadData();
    if (defaultFilters && !location.search) {
      handleFiltersChange({ ...defaultFilters });
    }
  }, [location.search]);

  return (
    <RenderFilters
      item={dateItem}
      filtersObject={filtersObject}
      setFiltersObject={setFiltersObject}
      loading={loading}
      handleFiltersChange={handleFiltersChange}
    />
  );
};

export default DateFilter;
