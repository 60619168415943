import React from 'react';
import DetailCardVertical from '../../detail-card/DetailCardVertical';
import { Button, Tooltip } from '../../../common';
import { useNavigate } from 'react-router-dom';
import { PATIENT_EDIT_PATH } from '../../../constants/admin-paths';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';
import Permission from '../../permission/Permission';
import { Flex } from 'antd';
import LeadContactSidebar from '../lead-contact-sidebar/LeadContactSidebar';

const LeadDetailCard = ({ detailData = {} }) => {
  const navigate = useNavigate();
  const title = `${detailData?.first_name} ${detailData?.last_name}`;

  const getDetailsData = () => {
    return [
      {
        label: 'Email',
        value: detailData?.email || '-',
        isPrimaryText: true,
        isTextCopy: true,
      },
      {
        label: 'Phone',
        value: detailData?.phone_number || '-',
        isPrimaryText: true,
        isTextCopy: true,
      },
      {
        label: 'Gender',
        value: detailData?.gender_label || '-',
      },
      {
        label: 'Date of Birth',
        value: detailData?.date_of_birth || '-',
      },
      {
        label: 'Secondary Email',
        value: detailData?.secondary_email || '-',
        isPrimaryText: true,
        isTextCopy: true,
      },
      {
        label: 'Secondary Phone',
        value: detailData?.secondary_phone_number || '-',
        isPrimaryText: true,
        isTextCopy: true,
      },
      {
        label: 'Address',
        value: detailData?.address?.address || '-',
      },
      {
        label: 'State',
        value: detailData?.address?.state || '-',
      },
      {
        label: 'Postal Code',
        value: detailData?.address?.postal_code || '-',
      },
    ];
  };

  const getActionContent = () => (
    <Flex gap={10}>
      <LeadContactSidebar patientId={detailData?.id} />
      <Permission models={PERMISSIONS_STRUCT.PATIENT.NAME} actions={PERMISSIONS_STRUCT.PATIENT.ACTIONS.UPDATE}>
        <Tooltip title="Edit Patient">
          <Button
            icon={'CiEdit'}
            size="medium"
            onClick={() => navigate(PATIENT_EDIT_PATH?.replace(':id', detailData?.id))}
          />
        </Tooltip>
      </Permission>
    </Flex>
  );

  return <DetailCardVertical cardTitle={title} cardData={getDetailsData()} ActionsList={getActionContent()} />;
};

export default LeadDetailCard;
