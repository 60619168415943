import { Flex } from 'antd';
import React, { useState } from 'react';
import cx from 'clsx';
import StatusButton from '../../utils/status/status_button';
import DisplayDateTime from '../../utils/time/display_time';
import styles from './ExternalLogs.module.scss';
import { Button } from '../../common';

const ExternalLogDetailCard = ({ data: logData = {} }) => {
  const { body = '', created_at = '', delivery_method = '', send_to = '', status = {}, subject = '' } = logData;
  const [readMore, setReadMore] = useState(false);
  const extraLines = body?.split('\n')?.length > 6;
  const shortenText = body?.split('\n').slice(0, 6).join('\n');

  return (
    <Flex vertical gap={10}>
      <Flex align="center" justify="space-between" className="mt-1" gap={10}>
        <Flex className="item-separator-dot">
          <div className="heading-3">{subject}</div>
          <div className="text-1">{send_to}</div>
          {status && (
            <div>
              <StatusButton status={status} />
            </div>
          )}
        </Flex>
        <Flex>{created_at && <div className="text-1">{DisplayDateTime(created_at)}</div>}</Flex>
      </Flex>
      <div className={cx(styles.bodyContainer, 'text-1 mb-2')}>
        <div>{readMore ? body : shortenText}</div>
        {extraLines && (
          <Button
            className={'mt-1'}
            type={'link'}
            text={readMore ? 'show less ' : 'show more'}
            onClick={() => setReadMore((prevReadMore) => !prevReadMore)}
          />
        )}
      </div>
    </Flex>
  );
};

export default ExternalLogDetailCard;
