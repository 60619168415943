import React from 'react';
import { Card, Flex } from 'antd';
import cx from 'clsx';
import { Skeleton } from '../../../common';
import styles from './StatsCard.module.scss';

const StatsCard = ({ stat = {}, pending = false }) => {
  if (pending)
    return (
      <Card>
        <Flex vertical gap={10}>
          <Skeleton height={15} width={80} />
          <Skeleton height={22} width={40} />
        </Flex>
      </Card>
    );

  return (
    <Card className={styles.statCardContent}>
      <Flex vertical gap={10}>
        <div className="subtitle-2 capitalize">{stat?.title ?? '-'}</div>
        <div className={cx('heading-1', styles.valueText)}>{stat?.value ?? '-'}</div>
      </Flex>
    </Card>
  );
};

export default StatsCard;
