import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Input, Select } from '../../../common';
import { Flex } from 'antd';
import { formatOptionsForSelect, formatValueForSelect } from '../../../utils/filters-util';

const PatientContactModalBody = forwardRef(
  ({ patientContactTypes = [], handleSubmit = () => {}, contactData = {}, isEdit = false }, ref) => {
    const formikRef = useRef();

    const getValidationSchema = () =>
      yup.object().shape({
        type: yup.object().nullable().required('Type is required'),
        id: yup.string().required('ID is required.'),
      });

    const getInitialValues = () => ({
      type: isEdit ? contactData?.type : null,
      id: isEdit ? contactData?.source_id : '',
    });

    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef?.current?.submitForm();
      },
    }));

    return (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const { values, handleSubmit, handleChange, setFieldValue, handleBlur, touched, errors } = formikProps;
          return (
            <form>
              <Flex vertical gap={20}>
                <Select
                  label={'Contact Type'}
                  placeholder={'Select Type'}
                  value={formatValueForSelect({
                    obj: values.type,
                    key: 'name',
                  })}
                  options={formatOptionsForSelect({ list: patientContactTypes })}
                  onChange={(option) => {
                    setFieldValue('type', option);
                  }}
                  onBlur={handleBlur('type')}
                  errorMsg={errors?.type && touched?.type && errors?.type}
                />
                <Input
                  placeholder="Enter ID"
                  name="id"
                  label="Contact Id"
                  value={values?.id}
                  errorMsg={errors?.id && touched?.id && errors?.id}
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                />
              </Flex>
            </form>
          );
        }}
      </Formik>
    );
  },
);

export default PatientContactModalBody;
