import React from 'react';
import { Flex, Switch as AntdSwitch } from 'antd';
import Label from '../label/Label';

const Switch = ({
  label = '',
  size = 'default',
  checked = false,
  handleChange = () => {},
  labelClassName = '',
  loading = false,
  ...style
}) => {
  return (
    <Flex align="center" justify="space-between" {...style}>
      {label && <Label className={labelClassName}>{label}</Label>}
      <AntdSwitch checked={checked} onChange={handleChange} size={size} loading={loading} />
    </Flex>
  );
};

export default Switch;
