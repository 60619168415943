import React from 'react';
import { isFieldRestricted } from '../../../utils';
import { Col, Empty, Flex, Row } from 'antd';
import styles from './LeadProducts.module.scss';

const LeadProduct = ({ product = {} }) => {
  return (
    <Flex vertical gap={20} className="list-item-border-bottom ">
      <Row gutter={[15, 20]}>
        <Col span={24}>
          <div className="subtitle-1">{!!product?.display_name ? product.display_name : product?.name ?? '-'}</div>
        </Col>
        <Col span={14}>
          <div className="text-2 mb-2">{'Price'}</div>
          <div className="text-1">{product?.price ?? '0'}</div>
        </Col>
        <Col span={10}>
          <div className="text-2 mb-2">{'Type'}</div>
          <div className="text-1">{product?.product_type ?? '-'}</div>
        </Col>
      </Row>
    </Flex>
  );
};

const LeadProducts = ({ leadProducts = [] }) => {
  return (
    <Flex vertical gap={25} className={styles.leadProductContainer}>
      <div className="heading-4">{'Products'}</div>
      <Flex
        vertical
        gap={25}
        style={{ flexGrow: 1, overflowY: 'auto' }}
        justify={leadProducts?.length ? 'normal' : 'center'}
      >
        {!isFieldRestricted(leadProducts) && leadProducts?.length ? (
          leadProducts?.map((product) => <LeadProduct key={product.id} product={product} />)
        ) : (
          <Empty />
        )}
      </Flex>
    </Flex>
  );
};

export default LeadProducts;
