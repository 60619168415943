import React from 'react';
import styles from './TimeLine.module.scss';
import cx from 'clsx';

const Timeline = ({
  className,
  icon,
  bgColor,
  containerSize,
  borderColor,
  borderRadius,
  style,
  TimeLineBodyComponent = null,
  timeLineData = [],
  iconWidth,
  iconBgColor,
  getBodyIcon,
}) => {
  const getIcon = (item = {}) => {
    if (getBodyIcon) {
      return getBodyIcon(item);
    }
    return icon;
  };

  return (
    <div
      className={cx(styles.TimelineContainer)}
      style={{
        ...style,
        '--round-icon-width': iconWidth,
        '--icon-bg-color': !!icon ? 'transparent' : iconBgColor,
        '--icon-color': 'inherit',
      }}
    >
      {timeLineData?.map((entry, index) => (
        <div key={index} className="d-flex">
          <div className={cx('d-inline-flex justify-content-center align-items-center mt-2', styles.timelineRound)}>
            {getIcon(entry)}
          </div>
          <div className={cx(styles.TimeLineBody, 'w-100')}>
            {TimeLineBodyComponent ? (
              <TimeLineBodyComponent data={{ ...entry }} />
            ) : (
              <div className={cx('d-flex justify-content-between', styles.scheduleTime)}>
                <div>{entry?.description ?? ''}</div>
                <div>{entry?.text ?? ''}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
