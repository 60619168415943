import React, { Fragment } from 'react';
import { DASHBOARD_TAB_SLUGS } from '../../../constants/dashboard-constants';
import { Flex } from 'antd';
import MetricStats from '../metric-stats/MetricStats';
import MetricGraph from '../metric-graph/MetricGraph';

const LeadMetrics = () => {
  return (
    <Fragment>
      <Flex vertical gap={20}>
        <MetricStats dashboardType={DASHBOARD_TAB_SLUGS.LEADS_TAB} />
        <MetricGraph title="Lead Trends" dashboardType={DASHBOARD_TAB_SLUGS.LEADS_TAB} />
      </Flex>
    </Fragment>
  );
};

export default LeadMetrics;
