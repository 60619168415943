import { Checkbox, Dropdown, Empty } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../common';
import styles from './ManageColumnDropDown.module.scss';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { updateUserColumnsApi } from '../../api/users-api';

const ManageColumnDropDown = ({
  disabled = false,
  onDropDownClose = () => {},
  sourceClass = '',
  columnOptions = [],
  buttonSize = 'large',
}) => {
  const options = useMemo(
    () =>
      columnOptions?.map((option) => {
        return {
          key: option?.id,
          label: option?.name,
          value: option,
        };
      }) ?? [],
    [columnOptions],
  );
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleSelectChange = (checkedValues) => {
    if (!!checkedValues?.length) {
      setSelectedColumns(checkedValues);
      setIsChanged(true);
    }
  };

  const updateUserCoumns = async () => {
    const selectedColumnKeys = [];
    const updatedColumns = columnOptions?.map((option) => {
      const selectedColIndex = selectedColumns?.findIndex((selectedCol) => selectedCol?.key === option?.key);
      if (selectedColIndex !== -1) {
        selectedColumnKeys.push({ key: option.key });
      }
      return {
        ...option,
        active: selectedColIndex === -1 ? false : true,
      };
    });

    try {
      setLoading(true);
      const payload = {
        user_column: {
          source_class: sourceClass,
          selected_columns: selectedColumnKeys,
        },
      };
      const res = await updateUserColumnsApi(payload);
      if (res && res?.status) {
        onDropDownClose(res?.active_columns ?? []);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  const onOpenChangeDropDown = (open) => {
    setOpenDropdown(open);
    setIsChanged(false);
    if (!open && isChanged) {
      updateUserCoumns();
    }
  };

  const filterSelectedColumns = (options = []) => {
    return options.filter((option) => option.active);
  };

  useEffect(() => {
    setSelectedColumns(filterSelectedColumns(columnOptions));
  }, [columnOptions]);

  return (
    <Dropdown
      open={openDropdown}
      onOpenChange={onOpenChangeDropDown}
      disabled={disabled}
      width={150}
      trigger={['click']}
      placement="bottomRight"
      destroyPopupOnHide
      dropdownRender={() => (
        <div className={styles.dropdownContent}>
          <div className="text-2">{'Customize Table'}</div>
          {options?.length ? (
            <div className="mt-3 scroll-y" style={{ '--box-height': '280px' }}>
              <Checkbox.Group
                options={options}
                value={selectedColumns}
                onChange={handleSelectChange}
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              />
            </div>
          ) : (
            <Empty className="mt-2" />
          )}
        </div>
      )}
    >
      <Button loading={loading} icon={'VscSettings'} size={buttonSize} onClick={(e) => e.preventDefault()} />
    </Dropdown>
  );
};

export default ManageColumnDropDown;
