import React from 'react';
import { Tabs as AntdTabs } from 'antd';
import { Card } from '../index';
import styles from './Tabs.module.scss';

const Tabs = ({
  tabsList = [],
  tabStyle = {},
  defaultActiveKey = '0',
  tabPosition = 'top',
  onTabClick = () => {},
  contentClassName = '',
  defaultTabBarExtraContent = () => {},
  getAction = () => {},
}) => {
  const tabBarExtraContent = () => {
    const selectedTab = tabsList.find((tab) => tab.key == defaultActiveKey);
    if (selectedTab) {
      return <>{getAction(selectedTab) ?? defaultTabBarExtraContent()}</>;
    }
    return defaultTabBarExtraContent();
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <div className={styles.tabsContainer}>
      <DefaultTabBar {...props} />
    </div>
  );

  return (
    <AntdTabs
      defaultActiveKey={String(defaultActiveKey)}
      style={tabStyle}
      tabPosition={tabPosition}
      onTabClick={onTabClick}
      tabBarExtraContent={tabBarExtraContent()}
      renderTabBar={renderTabBar}
      items={tabsList
        ?.filter((item) => item?.show)
        ?.map((tab) => {
          return {
            label: tab.label,
            key: String(tab.key),
            children: <div className={contentClassName}>{tab.children}</div>,
          };
        })}
    />
  );
};

export default Tabs;
