import { useSelector } from 'react-redux';
import { selectPermissions } from '../../reducers/selectors';

const usePermissions = () => {
  const { permissions } = useSelector(selectPermissions);

  const getModelActionsPermission = (model, action, checkAllActions) => {
    if (typeof action === 'string') {
      return permissions?.[model] && permissions?.[model]?.actions?.[action];
    } else {
      if (checkAllActions) {
        let checkAllAction = true;
        action?.forEach((item) => {
          if (!permissions?.[model]?.actions?.[item]) {
            checkAllAction = false;
          }
        });
        return permissions?.[model] && checkAllAction;
      } else {
        let checkAnyAction = false;
        action?.forEach((item) => {
          if (!permissions?.[model]?.actions?.[item]) {
            checkAnyAction = true;
          }
        });
        return permissions?.[model] && checkAnyAction;
      }
    }
  };

  const getPermission = ({ models = '', actions = '', checkAllActions = true }) => {
    if (permissions?.all) return true;

    if (typeof models === 'string') {
      return !!getModelActionsPermission(models, actions, checkAllActions);
    } else {
      let checkAllModels = false;
      models?.forEach((item) => {
        checkAllModels = !!getModelActionsPermission(item, actions, checkAllActions);
      });
      return checkAllModels;
    }
  };

  return {
    getPermission,
  };
};

export default usePermissions;
