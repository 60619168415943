import React, { useState } from 'react';
import styles from './AdvanceInputFilter.module.scss';
import { Checkbox, Flex } from 'antd';
import { Input } from '../../../common';
import { filterFieldFormat, getAdvanceFilterKey, getCurrentFilterKeyOption } from '../../../utils/filters-util';
import { FILTER_TYPES_OPTIONS } from '../../../constants/filters-constants';

const AdvanceInputFilter = ({
  item = {},
  filtersObject = {},
  setFiltersObject = () => {},
  handleFiltersChange = () => {},
  setSelectedAdvanceInput = () => {},
}) => {
  const itemKey = item?.selectedKey ?? getAdvanceFilterKey(item);
  const [selectedOption, setSelectedOption] = useState(getCurrentFilterKeyOption(item, itemKey));
  const onSearch = (object = filtersObject, onEnter = false) => {
    setFiltersObject({ ...object });
    !!onEnter && handleFiltersChange({ ...object });
  };

  const handleSelectChange = (option = {}) => {
    if (option !== selectedOption) {
      let currentValue = '';
      filtersObject.page && delete filtersObject.page;
      if (filtersObject?.[itemKey]) {
        currentValue = filtersObject[itemKey];
        delete filtersObject?.[itemKey];
      }
      const updatedKey = getAdvanceFilterKey(item, option.value);
      setSelectedAdvanceInput({
        ...item,
        selectedKey: updatedKey,
        selectedValue: option?.defaultValue ?? currentValue,
      });
      onSearch({ ...filtersObject, [updatedKey]: option?.defaultValue ?? currentValue }, true);
      setSelectedOption(option);
    }
  };

  return (
    <Flex vertical className={styles.advanceFiltersContainer} gap={25}>
      {!selectedOption?.defaultValue && (
        <Input
          value={filterFieldFormat(filtersObject[itemKey || ''])}
          label={item?.label ?? ''}
          placeholder={item?.placeholder ?? ''}
          type={item?.inputType ?? 'text'}
          allowClear={true}
          onChange={(e) => {
            filtersObject.page && delete filtersObject.page;
            setFiltersObject({
              ...filtersObject,
              [itemKey]: e.target.value,
            });
          }}
          onPressEnter={() => {
            if (item?.toLowerCase) {
              const obj = {
                ...filtersObject,
                [itemKey]:
                  item?.inputType === 'number' ? filtersObject[itemKey] : filtersObject[itemKey]?.toLowerCase(),
              };
              onSearch(obj, true);
            } else {
              onSearch(filtersObject, true);
            }
          }}
        />
      )}

      <Flex vertical gap={40}>
        {FILTER_TYPES_OPTIONS.map((option) => (
          <Checkbox key={option.key} checked={selectedOption === option} onClick={() => handleSelectChange(option)}>
            {option.label}
          </Checkbox>
        ))}
      </Flex>
    </Flex>
  );
};

export default AdvanceInputFilter;
