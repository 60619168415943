import { DropDown } from '../../common';

export const getActions = (actionList = []) => {
  const filteredActionList = actionList?.filter((action) => action?.permitted) ?? [];
  if (filteredActionList?.length) {
    return {
      name: 'Actions',
      cell: (row) => (
        <>
          <DropDown
            items={filteredActionList?.map((action) => {
              return {
                ...action,
                onClick: () => action?.onClick(row),
              };
            })}
          />
        </>
      ),
    };
  }
  return null;
};
