import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchQuestionnairesApi } from '../../api/questionnaires-api';
import { getQueryString } from '../../utils/filters-util';

const initialState = {
  questions: [],
  products: [],
  activeColumns: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchQuestionnairesListing = createAsyncThunk(
  'questionnaire/listing',
  async (updatedParamsObject = {}) => {
    try {
      const paramsObj = {
        'q[s]': 'updated_at desc',
        ...updatedParamsObject,
      };
      const res = await fetchQuestionnairesApi(getQueryString(paramsObj));
      return res;
    } catch (error) {
      toast.error(getErrorString(error));
    }
  },
);

const QuestionnairesSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setQuestionnairesLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setQuestionnairesActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuestionnairesListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchQuestionnairesListing.fulfilled, (state, action) => {
        if (state.loading) {
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.questions = action?.payload?.data || [];
        state.products = action?.payload?.products || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchQuestionnairesListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setQuestionnairesLoading, setQuestionnairesActiveColumn } = QuestionnairesSlice.actions;
export default QuestionnairesSlice.reducer;
