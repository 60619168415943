import { Line } from '@ant-design/plots';
import { Empty, Spin } from 'antd';
import { formateNumberWithCommas } from '../../utils';

const MultiLineGraph = ({
  //General
  data = [],
  xField = '',
  yField = '',
  colorField = '',
  shapeField = 'smooth',
  field = '',
  height = 400,
  width,
  smooth = true,
  autoFitChart = false,
  connectNullValues = true,
  annotations = [],
  //Labels
  showLabelsOnLine = false,
  labelFormatter = (value) => {
    return value?.[`${yField}`].toLocaleString();
  },
  labelStyleProps = {},
  xAxisLabelFormatter = (value) => {
    return value.toLocaleString();
  },
  yAxisLabelFormatter = (value) => {
    return value.toLocaleString();
  },
  xLabelStyleProps = {},
  yLabelStyleProps = {},
  autoHideXaxixLable = false,
  adjustXAxisLabel = false,
  //Tooltip
  showCustomToolTip = false,
  customToolTipContent = () => {},
  tooltipFormatter = (datum) => {
    return { name: datum[`${field}`], value: datum[`${yField}`] };
  },
  tooltipValueFormater = (value) => {
    return formateNumberWithCommas(value);
  },
  //Legend
  showLegend = true,
  legendLayout = 'horizontal',
  legendPosition = 'top-left',
  paginateLegend = true,
  legendItemNameFormatter = (value) => {
    return value.toLocaleString();
  },
  //Colors
  customColors = false,
  colorsArray = [],
  colorFormatter = () => {},
  //line style
  lineStyleProps = {},
  loading = false,
  //Scale
  scale = {},
  pending = false,
}) => {
  const config = {
    data,
    xField: xField,
    yField: yField,
    colorField: colorField,
    seriesField: field,
    shapeField: shapeField,
    height: height,
    smooth: smooth,
    autoFit: autoFitChart,
    connectNulls: connectNullValues,

    ...(!!width ? { width: width } : {}),

    ...(customColors
      ? !!colorsArray.length > 0
        ? { color: colorsArray }
        : { color: ({ type }) => colorFormatter(type) }
      : {}),

    ...(showLabelsOnLine
      ? {
          label: {
            style: {
              fill: labelStyleProps?.color ? labelStyleProps?.color : 'grey',
              fontSize: labelStyleProps?.fontSize ? labelStyleProps?.fontSize : 14,
            },
            formatter: (value) => labelFormatter(value),
          },
        }
      : {}),

    xAxis: {
      label: {
        formatter: (value) => xAxisLabelFormatter(value),
        autoHide: autoHideXaxixLable,
        autoRotate: adjustXAxisLabel,
        style: {
          fill: xLabelStyleProps?.color ? xLabelStyleProps?.color : 'grey',
          fontSize: xLabelStyleProps?.fontSize ? xLabelStyleProps?.fontSize : 14,
        },
      },
    },

    ...(scale
      ? {
          scale: scale,
        }
      : {}),

    yAxis: {
      nice: true,
      min: 0.1,
      label: {
        formatter: (value) => yAxisLabelFormatter(value),
        style: {
          fill: yLabelStyleProps?.color ? yLabelStyleProps?.color : 'grey',
          fontSize: yLabelStyleProps?.fontSize ? yLabelStyleProps?.fontSize : 14,
        },
      },
    },

    tooltip: { channel: 'y', valueFormatter: tooltipValueFormater },

    ...(showCustomToolTip && {
      tooltip: { customContent: (title, items) => customToolTipContent(title, items) },
    }),

    ...(showLegend
      ? {
          legend: {
            layout: legendLayout,
            position: legendPosition,
            flipPage: paginateLegend,
          },
        }
      : {}),

    style: {
      lineWidth: lineStyleProps?.lineWidth ? lineStyleProps?.lineWidth : 2,
    },

    annotations: annotations,
    loading: loading,
  };

  if (pending) return <Spin style={{ height: 396 }} className="center-content" />;
  if (data?.length > 0) return <Line {...config} />;
  return (
    <div className="">
      <Empty description={<span>{'No Data Found'}</span>} style={{ height: 396 }} className="center-content" />
    </div>
  );
};

export default MultiLineGraph;
