import { Flex } from 'antd';
import cx from 'clsx';
import Icon from '../icon/Icon';
import styles from './SelectItem.module.scss';

const SelectItem = ({ title = '', onClick = () => {} }) => {
  return (
    <Flex className={cx('', styles.selectItemContainer)} align="center" justify="space-between" onClick={onClick}>
      <div>{title}</div>
      <Icon icon={'ArrowRightIcon'} color={'var(--ant-color-text-quaternary)'} />
    </Flex>
  );
};

export default SelectItem;
